<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Setoran Simpanan</strong>
      </template>
      <div class="row pl-3 pr-3 pt-2 pb-2">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Register:</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input
                    class=""
                    :readOnly="true"
                    style="width: 200px;"
                    placeholder="Nama"
                    v-model="keterangan"
                  />
                <a-button class="ml-2"
        @click="carinasabahvisible = !carinasabahvisible">
                  <a-icon type="search"></a-icon>
                </a-button>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Tanggal:</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-date-picker
                    style="width: 120px;"
                    v-model="input.tgl"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Alamat:</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input
                    class=""
                    :readOnly="true"
                    placeholder="Alamat"
                    v-model="keterangan"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Keterangan:</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input
                    class=""
                    placeholder="Keterangan"
                    v-model="keterangan"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Rekening: </label>
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                  <cleave
                    :readOnly="false"
                    :options="{ delimiter: '.', blocks: [2, 2, 6, 2] }"
                    class="ant-input"
                    :raw="false"
                    style="width: 130px;"
                    v-model.lazy="norekening"
                    placeholder="Ketik No Rekening"
                    v-on:keydown.enter.native="checkRekening"
                  />
                  <a-select
                    label-in-value
                    show-search
                    class="ml-2"
                    :value="selectedName"
                    placeholder="Ketikkan Nama Nasabah"
                    style="width: 200px;"
                    :filter-option="false"
                    :not-found-content="
                      fetching ? 'Searching...' : 'Not Found!'
                    "
                    @search="fetchUser"
                    @change="(e) => handleChange(e, 'selectedName')"
                  >
                    <a-spin
                      v-if="fetching"
                      slot="notFoundContent"
                      size="small"
                    />
                    <a-select-option
                      v-for="(d, index) in dataRekening"
                      :key="index"
                    >
                      <!-- {{ d.rekening + ' - ' + d.nama }} -->
                      {{ d.nama + " - " + d.alamat }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Alamat:</label>
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                  <a-input
                    class=""
                    :readOnly="true"
                    placeholder="Alamat"
                    v-model="keterangan"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Pokok</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <cleave
                    :readOnly="false"
                    :options="{ numeral: true }"
                    class="ant-input"
                    :raw="true"
                    style="width: 200px; text-align: right;"
                    placeholder="Pokok"
                    v-model="input.pokok"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Wajib</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <cleave
                    :readOnly="false"
                    :options="{ numeral: true }"
                    class="ant-input"
                    :raw="true"
                    style="width: 200px; text-align: right;"
                    v-model="input.wajib"
                    placeholder="Wajib"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <label class="mt-1">Kas</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <cleave
                    :readOnly="false"
                    :options="{ numeral: true }"
                    class="ant-input"
                    :raw="true"
                    style="width: 200px; text-align: right;"
                    placeholder="Kas"
                    v-model="input.kas"
                  />
              <a-button class="btn btn-primary float-right" >Submit</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
          <a-table
            :columns="columns"
            :dataSource="datatable"
            size="small"
            :scroll="{ y: 400 }"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 500,
              hideOnSinglePage: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span v-if="column.dataIndex === 'name'">
                  {{ text }}
                </span>
                <span v-else>
                  {{ text }}
                </span>
              </template>
            </template>
            <template slot="cetak" slot-scope="text, record">
              <a-button
                class="btn btn-outline-success"
                @click="reGenerateValidasi(record)"
              >
                <a-icon type="printer" />
              </a-button>
            </template>
            <label slot="tgl" slot-scope="text">
              {{ formatTanggal(text) }}
            </label>
            <template slot="jumlah" slot-scope="text, record">
              {{
                record.debet !== 0
                  ? formatCurrency(record.debet)
                  : formatCurrency(record.kredit)
              }}
            </template>
          </a-table>
        </div>
      </div>
    </a-card>
    <a-modal
        v-model="carinasabahvisible"
        :dialogStyle="{ top: '10px' }"
        :footer="null"
        :width="800"
      >
        <template slot="title">
          <strong>{{ carinasabahtitle }} </strong>
          <label style="margin: unset;" v-if="settings.selectedRekening !== ''">
            | Active Rekening: {{ settings.selectedRekening }}</label
          >
        </template>
        <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
    <div class="row">
      <a-input class="ml-3" placeholder="Ketik Nama" v-model="inputModal.nama" style="width: 200px;" @keydown.enter="findData"></a-input>
      <a-button v-if="settings.selectedRekening !== ''" class="btn btn-outline-danger btn-sm ml-2" @click="clearRekening">Clear Selected Rekening</a-button>
      <a-divider type="horizontal" style="margin: 8px 8px;"/>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <label v-if="table.tableatas.datatable.length !== null && table.tableatas.datatable.length !== 0" class="text-primary" style="margin-left: .5rem; margin-top: .5rem; margin-bottom: unset !important;">Jumlah Data: {{ table.tableatas.datatable.length }}</label>
        <a-table
          :scroll="{ y: 300 }"
          :columns="table.tableatas.column"
          :dataSource="table.tableatas.datatable"
          :pagination="false"
          size="small"
          :customRow="customRow"
        >
        </a-table>
      </div>
    </div>
        <!-- <lou-cari-nasabah /> -->
      </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    cleave: Cleave,
  },
  watch: {
    'settings.selectedRekening'() {
      this.checkRekening()
    },
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      fetching: false,
      maskInput: {
        namaregister: '',
        alamatregister: '',
        namarekening: '',
        alamatrekening: '',
      },
      input: {
        faktur: '',
        tgl: moment(),
        register_id: '',
        pokok: '',
        wajib: '',
        kas: '',
        rekening_id: '',
        keterangan: '',
        username: '',
      },

      // a-table Property
      columns: [
        {
          title: 'Tanggal',
          width: 100,
          dataIndex: 'tgl',
          scopedSlots: { customRender: 'tgl' },
          // width: 100,
        },
        {
          title: 'Faktur',
          dataIndex: 'faktur',

          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 100,
        },
        {
          title: 'Nama',
          dataIndex: 'nama',

          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 100,
        },
        {
          title: 'Nama Instansi',
          dataIndex: 'nama_instansi',

          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_instansi
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 100,
        },
        {
          title: 'Pokok',
          dataIndex: 'pokok',
          scopedSlots: { customRender: 'nominal' },
        },
        {
          title: 'Wajib',
          dataIndex: 'wajib',
          scopedSlots: { customRender: 'nominal' },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',

          scopedSlots: { customRender: '' },
        },
        {
          title: 'Username',
          dataIndex: 'username',

          scopedSlots: { customRender: '' },
        },
      ],
      datatable: [],
      componentKey: 0,

      // Modal Property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
          ],
          datatable: [],
        },
      },
    }
  },
  methods: {
    moment,
    async findData() {
      var imbuhan = ''
      imbuhan += this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      var res = await lou.customUrlGet2('simpanan/cari' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet('mutasitabungan/cari?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet(
            'mutasitabungan/cari_rekening/' + nvalue1,
          )
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
      }
    },
    async checkRekening() {
      if (this.norekening.length === 15) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet(
          'mutasitabungan/cari_rekening/' + this.norekening.toString(),
        )
        if (res) {
          if (res.data.length !== 0) {
            // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
            this.dataRekening = res.data
            var value = this.dataRekening.findIndex(
              (x) => x.rekening === this.norekening,
            )
            var dataTarget = this.dataRekening[value]
            var data = {
              selectedName: { key: value, label: dataTarget.nama },
              selectedRekening: { key: value, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    async getAllData() {
      var resp = await lou.customUrlGet('simpanan')
      if (resp) {
        this.datatable = resp.data
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    formatTanggal(v) {
      var ntgl = v.tgl.substring(0, 10)
      // console.log('ntgl', ntgl)
      return moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(record.kode.indexOf('.') + 1, record.kode.length)
        this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
